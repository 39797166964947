<template>
  <div class="flex flex-col gap-1" v-bind="attrs">
    <es-sheet v-for="task in tasksArray.filter(_ => !_?.isHidden)" :key="task!.id"
              class="flex flex-nowrap gap-2 max-w-fit ml-auto shadow-2xl items-center justify-center">
      <es-spinner />
      <span class="text-sm">{{ task!.label }}</span>
    </es-sheet>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onMounted} from 'vue'
import {EsSpinner, EsSheet} from "@esigndoc/ui";
import {useStore} from "@nanostores/vue";
import {$tasks} from "@/stores/app-store.ts"

export default defineComponent({
  components: {EsSpinner, EsSheet},
  inheritAttrs: false,
  setup(props, { attrs }) {
    const tasks = useStore($tasks)
    const tasksArray = computed(() => Object.values(tasks.value))

    return {tasksArray, attrs}
  }
})
</script>

<style scoped>

</style>
